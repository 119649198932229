import { useState } from 'react';
import useConfig from 'hooks/useConfig';

import './AddEditLocal.scss';
import moment from 'moment';
import Connector from '../../connector';
import * as Yup from 'yup';
import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import {
  TimeInput,
  TextInput,
  Dropdown,
  WarningButton,
  DangerButton,
  SuccessButton,
  CashInput,
} from 'components/CustomeMaterial';
import { map } from 'lodash';
import { useEffect } from 'react';

const AddEditLocal = ({ onClose, id, refetchData, clientId }) => {
  const { PAY_TYPES, PRODUCTS, UNITS } = useConfig();

  const filteredProducts = PRODUCTS.filter(item => !item.archive);

  const priceConfig = {};
  filteredProducts.forEach(product => {
    priceConfig[product.id] = 0;
  });

  const [initialValues, setInitialValues] = useState({
    country: '',
    postalCode: '',
    city: '',
    localName: '',
    address: '',
    phone: '',
    email: '',
    selfBiling: false,
    payment: 1,
    openingTime: '08:00:00',
    closingTime: '23:00:00',
    id,
    archive: false,
    priceConfig,
    clientId,
  });

  const fetchLocationData = async () => {
    const response = await Connector.getLocation(id);
    console.log(response.data);
    const parsedPriceConfig = JSON.parse(response.data.priceConfig);
    setInitialValues({
      ...response.data,
      priceConfig: parsedPriceConfig,
    });
  };

  useEffect(() => {
    if (id) {
      fetchLocationData();
    }
  }, []);

  const validationSchema = Yup.object().shape({
    country: Yup.string().required('Kraj jest wymagany'),
    postalCode: Yup.string().required('Kod pocztowy jest wymagany'),
    city: Yup.string().required('Miasto jest wymagane'),
    localName: Yup.string().required('Nazwa lokalu jest wymagana'),
    address: Yup.string().required('Adres jest wymagany'),
    openingTime: Yup.string().required('Godzina otwarcia jest wymagana'),
    closingTime: Yup.string().required('Godzina zamknięcia jest wymagana'),
    payment: Yup.number().required('Wybór płatności jest wymagany'),
  });

  const onAddLocation = async values => {
    const dataToPost = {
      ...values,
      priceConfig: JSON.stringify(values.priceConfig),
      selfBiling: values.selfBiling === 0 ? false : true,
      createdAt: moment(id?.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      additionalInfo: values?.additionalInfo || '',
      archive: false,
    };

    if (dataToPost?.clients) {
      delete dataToPost.clients;
    }

    await Connector.postLocation(dataToPost);
    onClose();
    refetchData();
  };

  const onRemoveLocal = async () => {
    await Connector.deleteLocation(id);
    onClose();
    refetchData();
  };

  if (id && !initialValues.localName) {
    return <div>Loading...</div>;
  }

  return (
    <div className="AddEditLocal-module">
      <FormikWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onAddLocation}
      >
        <div className="form-container">
          <TextInput id="localName" placeholder="Nazwa lokalu" />
          <TextInput id="country" placeholder="Kraj" />
          <TextInput id="city" placeholder="Miasto" />
          <TextInput id="address" placeholder="Ulica" />
          <TextInput id="postalCode" placeholder="Kod pocztowy" />
          <TextInput id="phone" placeholder="Telefon" />
          <TextInput id="email" placeholder="Email" />
          <TimeInput id="openingTime" placeholder="Godzina otwarcia" />
          <TimeInput id="closingTime" placeholder="Godzina zamknięcia" />
          <Dropdown
            id="payment"
            label="Płatność"
            options={map(PAY_TYPES, (pay, index) => ({
              value: index,
              label: pay,
            }))}
          />

          {filteredProducts.map((product, index) => (
            <CashInput
              key={index}
              id={`priceConfig.${product.id}`}
              placeholder={`${product.name} - (zł / ${UNITS[product.unitId]})`}
            />
          ))}
        </div>
        <div className="form-buttons">
          <SuccessButton type="submit">Zapisz</SuccessButton>
          {id && (
            <DangerButton onClick={onRemoveLocal}>Usuń lokal</DangerButton>
          )}
          <WarningButton onClick={onClose}>Anuluj</WarningButton>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default AddEditLocal;
